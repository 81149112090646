<template>
  <div class="home_wrap">
    <div class="banner_wrap">
      <img :src="banner" alt="" />
      <div class="banner-title">连接市场供求，赋能AI生态</div>
    </div>
    <div class='search-wrap'>
      <el-input v-model='searchKey' @keyup.enter.native="search" placeholder='请输入应用名称搜索'></el-input>
      <el-button @click='search' type='primary' icon='el-icon-search'>查 询</el-button>
    </div>
    <div class='main_wrap'>
      <div class='firstLine'>
        <div class='item' @click="goAppPublicize(item)" v-for='item in list1' :key='item.index'
          :style="{ 'backgroundImage' : `url(${ item.background })`}">
          <div class='box-title' :style="{ 'backgroundImage' : `url(${ item.title })`}"></div>
        </div>
      </div>
      <div class='secondLine'>
        <div class='item' @click="goAppPublicize(item)" v-for='item in list2' :key='item.index'
          :style="{ 'backgroundImage' : `url(${ item.background })`}">
          <div class='box-title' :style="{ 'backgroundImage' : `url(${ item.title })`}"></div>
        </div>
      </div>
      <div class='thirdLine' :style="{ 'backgroundImage' : `url(${ require('@/assets/images/home/msx.png') })`}">
        <div class="third_left">
          <!-- <div class="mlu290"></div> -->
          <div class="mlu370">
            <div class="info_title">
              <div class="name">寒武纪</div>
              <div class="model">MLU370</div>
            </div>
            <div class="specs-container">
              <div class="specs-item">
                <div>INT8</div>
                <div>256TOPS</div>
              </div>
              <div class="specs-item">
                <div>FP16</div>
                <div>128TOPS</div>
              </div>
              <div class="specs-item">
                <div>FP32</div>
                <div>24TFOPS</div>
              </div>
              <div class="specs-item">
                <div>显存</div>
                <div>48GB LPDDR5</div>
              </div>
            </div>
          </div>
        </div>
        <div class="third_right">
          <div class="tr_top">AI生产力平台</div>
          <div class="tr_bot">
            <div class="hint">
              无需编码，无师自通，五分钟获得您的专属AI算法，快来体验吧！
            </div>
            <el-button class="trial_btn" @click="goMSX">申请试用</el-button>
          </div>
        </div>
      </div>
      <div class='fourthLine' :style="{ 'backgroundImage' : `url(${ require('@/assets/images/home/suanli.png') })`}">
        <div class="name">算力服务器</div>
      </div>
    </div>

    <common-footer></common-footer>

    <!-- Dialog for Disclaimer -->
    <el-dialog title="免责声明" :visible.sync="isDisclaimerOpen" width="50%" @close="handleDisclaimerClose"
      :show-close="false" class="disclaimer">
      <p>在使用本网站提供的体验服务（以下简称本服务）前，请您务必仔细阅读并理解透彻本免责声明。如您选择继续使用本服务，意味着您充分知悉并接受以下使用条件：
      </p>
      <p> 1.您使用本服务发表的内容及文件应符合法律法规的要求，如我方独立判断您存在违规使用行为时，我方有权单方决定处置措施，包括但不限于删除您上传的内容或文件、限制使用等我方认为可用以合理处置违规行为的措施。
      </p>
      <p> 2.您理解并确认，本服务并不为您提供存储您所上传的信息及文件的空间，您需自行负责数据的存储以及备份。</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" class="common-btn" @click="isDisclaimerOpen = false">我同意</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import commonFooter from '@/components/commonFooter'
/* eslint-disable */
import api from '@/api/api'
export default {
  components: {
    commonFooter,
  },
  created () {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    if (!sessionStorage.getItem('disclaimerShown')) {
      this.openDisclaimer();

    }
  },
  data () {
    return {
      banner: require('@/assets/images/home/banner.png'),
      imglist: [
        require('@/assets/images/banner.png')
      ],
      searchKey: '',
      list1: [
        { label: '数字人', background: require('@/assets/images/home/shuziren.png'), title: require('@/assets/images/home/shuzirentitle.png'), url: '', id: '', type: '1' },
        { label: 'AI应用', background: require('@/assets/images/home/aiapp.png'), title: require('@/assets/images/home/aiapptitle.png'), url: '', id: '', type: '2' },
      ],
      list2: [
        { label: '大模型算法', background: require('@/assets/images/home/damoxing.png'), title: require('@/assets/images/home/damoxingtitle.png'), url: '', id: '', type: '3' },
        { label: '图像识别算法', background: require('@/assets/images/home/tuxiang.png'), title: require('@/assets/images/home/tuxiangtitle.png'), url: '', id: '', type: '4' },
        { label: '语音识别算法', background: require('@/assets/images/home/yuyin.png'), title: require('@/assets/images/home/yuyintitle.png'), url: '', id: '', type: '5' },
      ],
      isDisclaimerOpen: false,

    }
  },
  computed: {
    aiUrl () {
      return this.$store.state.config.aiUrl;
    }
  },
  methods: {
    openDisclaimer () {
      this.isDisclaimerOpen = true; // 页面加载时打开免责声明
    },
    handleDisclaimerClose () {
      this.isDisclaimerOpen = false;
      sessionStorage.setItem('disclaimerShown', 'true');
    },
    handleJump (e) {
      if (e.id == 0) {
        this.$router.push('/aboutus')
      } else if (e.id == 1) {
        this.$router.push('/aiCase')
      } else {
        this.$router.push('/industryCases')
      }
    },
    search () {
      if (this.searchKey) window.localStorage.setItem('searchKey', this.searchKey)
      this.$router.push({
        path: '/appMarket',
      })
    },
    goAppPublicize ({ type }) {
      const route = type === '3'
        ? '/appPublicize'
        : {
          path: '/appMarket',
          query: { type }
        };
      this.$router.push(route);

    },
    goMSX () {
      let url = this.aiUrl.startsWith('http://') || this.aiUrl.startsWith('https://') ? this.aiUrl : 'http://' + this.aiUrl;
      window.open(url, '_blank');
    }
  }
}
</script>
<style lang="scss">
.home_wrap {
  position: relative;
  .banner_wrap {
    position: relative;
    img {
      height: 155px;
      width: 100%;
      object-fit: cover;
      object-position: center -55px; /* 控制图片内容的位置 */
    }
    .banner-title {
      position: absolute;
      /* font-size: 69.33px; */
      font-size: 59.33px;
      font-family: PangMenZhengDao;
      font-weight: normal;
      text-align: justify;
      color: #ffffff;
      line-height: 78.67px;
      letter-spacing: 4.67px;
      text-shadow: 2.67px 5.33px 0px 0px rgba(62, 111, 250, 0.5);
      z-index: 11;
      top: 30px;
      left: 240px;
    }
    .banner-subTitle {
      position: absolute;
      top: 83px;
      left: 240px;
      /* font-size: 32px; */
      font-size: 22px;
      font-weight: 400;
      text-align: justify;
      color: #ffffff;
      line-height: 46.67px;
    }
  }
  .search-wrap {
    position: absolute;
    width: 1066.67px;
    height: 56px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 37.33px;
    box-shadow: 0px 4px 12px 0px rgba(12, 64, 121, 0.12),
      0px 4px 8px -8px rgba(0, 0, 0, 0.04);
    display: flex;
    left: 50%;
    transform: translateX(-50%);
    top: 130px;

    .el-input__inner {
      box-sizing: border-box;
      width: 100% !important;
      height: 100% !important;
      border-radius: 37.33px;
      padding-left: 60px;
      border: none !important;
      font-size: 16px;
    }
    .el-button {
      position: absolute;
      width: 106.67px;
      height: 56px;
      background: #2774f7;
      border-radius: 37.33px;
      box-shadow: 0px 4px 12px 0px rgba(12, 64, 121, 0.12),
        0px 4px 8px -8px rgba(0, 0, 0, 0.04);
      right: 0px;
      border: 1px solid #2774f7;
      font-size: 16px;
    }
  }
  .main_wrap {
    width: 1440px;
    margin: 0 auto;
    margin-top: 45px;
    .el-button {
      width: 106.67px;
      height: 40px;
      background: #ffffff;
      border-radius: 21.33px;
      font-size: 18.67px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #1c6ef5;
      margin-left: 13px;
    }
    .firstLine,
    .secondLine {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #2774f7;
      .item {
        width: 712.67px;
        /* height: 213.33px; */
        height: 170px;
        cursor: pointer;
        background-size: cover;
        background-position: center 60%;
        box-sizing: border-box;
        padding: 20px;
        .box-title {
          width: 665.33px;
          height: 53.33px;
          background-repeat: no-repeat;
          background-size: 50%;
        }
      }
    }
    .secondLine {
      margin-top: 10px;
      .item {
        width: 471.33px;
        /* height: 213.33px; */
        height: 170px;
      }
    }
    .thirdLine {
      margin-top: 10px;
      width: 1440px;
      /* height: 173.33px; */
      height: 140px;
      background-size: cover;
      display: flex;
      box-sizing: border-box;
      border-radius: 13px;
      padding: 0px 26px;
      .third_left {
        width: 452px;
        display: flex;
        justify-content: center;

        > div {
          width: 226.67px;
          height: 120.67px;
          margin-top: 5px;
        }
        .mlu370 {
          background: url("~@/assets/images/home/mlu370.png");
          background-size: 100% 100%;
          position: relative;
          .info_title {
            position: absolute;
            top: 30px;
            font-weight: 700;
            color: #ffffff;
            left: 132px;
            .name {
              font-size: 12px;
            }
            .model {
              margin-top: 5px;
              font-size: 16px;
            }
          }
          .specs-container {
            position: absolute;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            font-size: 20px;
            grid-column-gap: 15px;
            grid-row-gap: 10px;
            box-sizing: border-box;
            padding: 5px 10px;
            bottom: 0px;
            .specs-item {
              display: flex;
              align-items: center;
              justify-content: space-around;
              font-size: 10px;
              color: #ffffff;
              div:first-child {
                white-space: nowrap;
                font-weight: 400;
              }
              div:last-child {
                white-space: nowrap;
                font-weight: bold;
                margin-left: 3px;
              }
            }
          }
        }
      }
      .third_right {
        flex: 1;
        margin-left: 85px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .tr_top {
          font-size: 32px;
          font-family: PangMenZhengDao;
          font-weight: 400;
          color: #ffffff;
        }
        .tr_bot {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .hint {
            font-size: 16px;
            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
          }
        }
      }
    }

    .fourthLine {
      margin-top: 10px;
      margin-bottom: 10px;
      width: 1440px;
      height: 89.33px;
      background-size: cover;
      position: relative;
      display: flex;
      .name {
        font-size: 32px;
        font-family: PangMenZhengDao;
        font-weight: normal;
        text-align: justify;
        color: #ffffff;
        line-height: 36px;
        letter-spacing: 2.28px;
        position: absolute;
        top: 27px;
        left: 72px;
      }
      .el-button {
        position: absolute;
        top: 15px;
        right: 56px;
      }
    }

    .trial_btn {
      font-size: 15px;
    }
  }
  .disclaimer {
    .el-dialog__body {
      padding: 15px;
      p {
        text-align: left;
        font-size: 14px;
      }
    }
  }
}
</style>

